import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import Container from "@mui/material/Container";
import * as ActionTypes from "../../../redux/actions";
import { catchError } from "../../../lib/functions";
import {
  newPublishedBook,
  updatePublishedBook,
  getAllPublishedSeries,
} from "../../../controller/publishingController";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";

const NewBook = ({
  openDrawer,
  openDrawerData,
  closeDrawer,
  setRefresh,
  isEditing,
  userLogin,
}) => {
  const [t] = useTranslation();

  const drawerWidth = 750;
  const [open, setOpen] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [soon, setSoon] = useState(false);
  const changeAuthor = (e) => {
    setSelectedAuthor(e.target.value);
  };
  const changeType = (e) => {
    setSelectedType(e.target.value);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      seriesID: "",
      picture: "",
      num: "",
      linkAudio: "",
      linkAmazon: "",
      linkComic: "",
      type: "",
      soon: false,
      release: false,
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      values.seriesID = selectedAuthor;
      values.type = selectedType;
      if (isEditing === false) {
        callUpdate(values, openDrawerData.id, openDrawerData.bookPosition);
      } else {
        callNew(values);
      }
    },
  });

  const sendForm = (val) => {
    if (val === 2) {
      formik.values.soon = true;
    }
    if (val === 3) {
      formik.values.soon = false;
      formik.values.release = true;
    }
    formik.submitForm();
  };

  const callUpdate = async (values, id, position) => {
    try {
      let res = await updatePublishedBook(values, id, position, userLogin);
      if (res.data.success === true) {
        toast.success("Book Updated", { theme: "colored" });
        closeModal();
      } else {
        throw new Error();
      }
    } catch (error) {
      catchError("Failed to update book");
    }
  };

  const callNew = async (values) => {
    try {
      let res = await newPublishedBook(values, userLogin);
      if (res.data.success === true) {
        toast.success("Book Created", { theme: "colored" });
        closeModal();
      } else {
        throw new Error();
      }
    } catch (error) {
      catchError("Failed to create new book");
    }
  };
  const callPublishedSeries = async () => {
    try {
      let res = await getAllPublishedSeries(userLogin);
      if (res.data.success === true) {
        setAuthors(res.data.data);
      } else {
        throw new Error();
      }
    } catch (error) {
      catchError(error, "Failed to load all published authors");
    }
  };

  useEffect(() => {
    console.log(isEditing);
    console.log(soon);
    console.log(openDrawerData);
    if (openDrawer === "show") {
      callPublishedSeries();
      if (openDrawerData !== null) {
        let transData = {
          name: openDrawerData.name,
          seriesID: openDrawerData.seriesID,
          num: openDrawerData.num,
          description: openDrawerData.description,
          linkAmazon: openDrawerData.linkAmazon,
          linkAudio: openDrawerData.linkAudio,
          linkComic: openDrawerData.linkComic,
          soon: openDrawerData.soon
        };
        setSoon(openDrawerData.soon);
        setSelectedAuthor(openDrawerData.seriesID);
        setSelectedType(openDrawerData.type);
        formik.resetForm({ values: transData });
      }

      setOpen(true);
    }
  }, [openDrawer]);

  const closeModal = () => {
    closeDrawer();
    formik.resetForm({
      values: {
        name: "",
        seriesID: "",
        picture: "",
        num: "",
        linkAudio: "",
        linkAmazon: "",
        linkComic: "",
        type: "",
        soon: false,
        release: false,
      },
    });
    setSoon(false);
    setSelectedAuthor(null);
    setSelectedType(null);
    setOpen(false);
    setRefresh();
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const handleFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async function (event) {
      try {
        const base64Data = event.target.result.split(",")[1]; // Extract base64 data from data URL
        // Now you can use base64Data as needed, such as saving it to formik.values.picture
        formik.values.picture = base64Data;
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    };

    reader.readAsDataURL(file); // Read the file as data URL asynchronously
  };
  return (
    <Drawer
      PaperProps={{
        sx: {
          backgroundColor: "secondary.main",
          color: "black",
        },
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="temporary"
      anchor="right"
      open={open}
    >
      <Toolbar>
        <Typography variant="h5">New Book / Comic</Typography>
      </Toolbar>
      <Divider />
      <Container>
        <TextField
          sx={{ mt: 3, width: "100%" }}
          id="outlined-required"
          label="Name"
          placeholder="Name"
          {...formik.getFieldProps("name")}
        />
        <FormControl fullWidth sx={{ width: "100%", mt: 3 }}>
          <InputLabel id="inputAuthor">Belongs to Serie</InputLabel>
          <Select
            labelId="inputAuthor"
            label="Belongs to Serie"
            id="inputAuthor"
            value={selectedAuthor}
            onChange={(e) => changeAuthor(e)}
          >
            {authors.map((data, key) => (
              <MenuItem key={key} name={data.name} value={data.id}>
                {data.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          sx={{ mt: 3, width: "100%" }}
          id="outlined-required"
          label="Number of Book"
          placeholder="Number of Book"
          {...formik.getFieldProps("num")}
        />

        <FormControl fullWidth sx={{ width: "100%", mt: 3 }}>
          <InputLabel id="inputTpe">Type</InputLabel>
          <Select
            labelId="inputTpe"
            label="Type"
            id="inputTpe"
            value={selectedType}
            onChange={(e) => changeType(e)}
          >
            <MenuItem key="Book" name="Book" value="Book">
              Book
            </MenuItem>
            <MenuItem key="Comic" name="Comic" value="Comic">
              Comic
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          multiline
          sx={{
            mt: 3,
            width: "100%",
            "& textarea": {
              maxHeight: "250px",
              overflowY: "overlay !important",
            },
          }}
          id="outlined-required"
          label="Description"
          placeholder="Description"
          {...formik.getFieldProps("description")}
        />
        <TextField
          sx={{ mt: 3, width: "100%" }}
          id="outlined-required"
          label="Amazon Link"
          placeholder="Amazon Link"
          {...formik.getFieldProps("linkAmazon")}
        />
        <TextField
          sx={{ mt: 3, width: "100%" }}
          id="outlined-required"
          label="Audio Link"
          placeholder="Audio Link"
          {...formik.getFieldProps("linkAudio")}
        />
        <TextField
          sx={{ mt: 3, mb: 3, width: "100%" }}
          id="outlined-required"
          label="Comic Link"
          placeholder="Comic Link"
          {...formik.getFieldProps("linkComic")}
        />
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Upload Cover
          <VisuallyHiddenInput
            id="custom-file-input"
            onChange={(e) => handleFile(e)}
            type="file"
          />
        </Button>
      </Container>

      <Box
        sx={{
          backgroundColor: "cMoon.main",
          position: "fixed",
          bottom: 0,
          width: "750px",
          p: 1,
        }}
      >
        <Stack direction="row" justifyContent="end" spacing={2}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => closeModal()}
          >
            {t("general.close")}
          </Button>

          {isEditing === false ? (
            <Button variant="contained" onClick={() => sendForm(1)}>
              Update Book
            </Button>
          ) : null}

          {soon === true && isEditing === false ? (
            <Button variant="contained" onClick={() => sendForm(3)}>
              Publish Book
            </Button>
          ) : null}

          {isEditing === false ? null : (
            <Button variant="contained" onClick={() => sendForm(1)}>
              New Published Book
            </Button>
          )}
          {isEditing === false ? null : (
            <Button variant="contained" onClick={() => sendForm(2)}>
              Coming Soon Book
            </Button>
          )}
        </Stack>
      </Box>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  openDrawer: state.publishing.openBook,
  openDrawerData: state.publishing.data,
  isEditing: state.publishing.isEditing,
  userLogin: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  closeDrawer: () => dispatch({ type: ActionTypes.CLOSE_NEW_BOOK }),
  setRefresh: () => dispatch({ type: ActionTypes.REFRESH }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewBook);
