import * as ActionTypes from "../actions";

//Handling config state

const initialState = {
  openSeries: "hide",
  data: null,
  openOptionsAP: "hide",
  openViewTemplate: "hide",
  openViewTemplateMode: "template",
  refresh: "no",
  bulk: false,
  openAuthor: "hide",
  openBook: "hide",
  openComic: "hide",
  isEditing: false,
  openOptionsAP: "hide",
  openNewNews: "hide",
};

const reducer = (state = initialState, action) => {
  if (action.type === ActionTypes.REFRESH) {
    return {
      ...state,
      refresh: "yes",
    };
  }
  if (action.type === ActionTypes.NO_REFRESH) {
    return {
      ...state,
      refresh: "no",
    };
  }
  if (action.type === ActionTypes.OPEN_NEW_AUTHOR) {
    return {
      ...state,
      isEditing: action.edit,
      data: action.data,
      openAuthor: "show",
    };
  }
  if (action.type === ActionTypes.CLOSE_NEW_AUTHOR) {
    return {
      ...state,
      openAuthor: "hide",
    };
  }
  if (action.type === ActionTypes.OPEN_NEW_COMIC) {
    return {
      ...state,
      isEditing: action.edit,
      data: action.data,
      openComic: "show",
    };
  }
  if (action.type === ActionTypes.CLOSE_NEW_COMIC) {
    return {
      ...state,
      openComic: "hide",
    };
  }
  if (action.type === ActionTypes.OPEN_NEW_BOOK) {
    return {
      ...state,
      isEditing: action.edit,
      data: action.data,
      openBook: "show",
    };
  }
  if (action.type === ActionTypes.CLOSE_NEW_BOOK) {
    return {
      ...state,
      data: null,
      openBook: "hide",
    };
  }
  if (action.type === ActionTypes.OPEN_NEW_SERIES) {
    return {
      ...state,
      isEditing: action.edit,
      data: action.data,
      openSeries: "show",
    };
  }
  if (action.type === ActionTypes.OPEN_NEW_NEWS) {
    
    return {
      ...state,
      isEditing: action.edit,
      data: action.data,
      openNewNews: "show",
    };
  }
  if (action.type === ActionTypes.CLOSE_NEW_NEWS) {
    return {
      ...state,
      openNewNews: "hide",
    };
  }
  if (action.type === ActionTypes.CLOSE_NEW_SERIES) {
    return {
      ...state,
      openSeries: "hide",
    };
  }

  

  return { ...state };
};

export default reducer;
